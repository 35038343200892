import { StateContext } from '@ngxs/store';
import { ResolvedShiftsStateModel } from '../resolved-shifts.state';
import {
  addDays,
  compareAsc,
  differenceInSeconds,
  isAfter,
  isBefore,
  isEqual,
  isWithinInterval,
  max,
  min,
  subDays,
} from 'date-fns';

/**
 * Quickcheck to determine if hydration is required
 *
 * @export
 * @param {StateContext<ResolvedShiftsStateModel>} ctx
 * @param {number} skipIfLastHydratedWithinSeconds
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {boolean} Returns true if a valid hydration history entry is found that covers desired range
 */
export function shouldSkipHydration(
  ctx: StateContext<ResolvedShiftsStateModel>,
  skipIfLastHydratedWithinSeconds: number,
  startDate: Date,
  endDate: Date,
): boolean {
  if (skipIfLastHydratedWithinSeconds) {
    const hydrationHistory = [...ctx.getState().history.hydration].sort(
      (a, b) => compareAsc(a.date, b.date),
    );

    const hydrationLogsContainingRange = hydrationHistory.filter(
      (log) =>
        isWithinInterval(startDate, {
          start: log.startDate,
          end: log.endDate,
        }) &&
        isWithinInterval(endDate, { start: log.startDate, end: log.endDate }),
    );
    if (hydrationLogsContainingRange?.length) {
      const latestHydrationContainingRange =
        hydrationLogsContainingRange[hydrationLogsContainingRange.length - 1];
      const secondsSinceLastHydration = differenceInSeconds(
        new Date(),
        latestHydrationContainingRange.date,
      );
      if (secondsSinceLastHydration < skipIfLastHydratedWithinSeconds) {
        return true;
      }
    }
  }

  return false;
}

/**
 * Determines which parts of desired range are covered by valid hydrations in history
 * and returns array of missing ranges
 *
 * @export
 * @param {StateContext<ResolvedShiftsStateModel>} ctx
 * @param {number} skipIfLastHydratedWithinSeconds
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {{}} array of date ranges that have outdated or no cache present
 */
export function determinePartialHydrationRanges(
  ctx: StateContext<ResolvedShiftsStateModel>,
  skipIfLastHydratedWithinSeconds: number,
  startDate: Date,
  endDate: Date,
): { startDate: Date; endDate: Date }[] {
  const hydrationHistory = [...ctx.getState().history.hydration].sort((a, b) =>
    compareAsc(a.date, b.date),
  );

  const recentHydratedIntervals = hydrationHistory.filter(
    (log) =>
      isWithinInterval(startDate, { start: log.startDate, end: log.endDate }) ||
      isWithinInterval(endDate, { start: log.startDate, end: log.endDate }) ||
      (isBefore(log.startDate, startDate) && isBefore(endDate, log.endDate)),
  );

  const validHydratedIntervals = recentHydratedIntervals.filter((log) => {
    const secondsSinceLastHydration = differenceInSeconds(new Date(), log.date);
    return secondsSinceLastHydration < skipIfLastHydratedWithinSeconds;
  });

  const sortedIntervals = validHydratedIntervals.sort((a, b) =>
    compareAsc(a.startDate, b.startDate),
  );

  const dateRangesToHydrate: { startDate: Date; endDate: Date }[] = [];
  let currentStart = startDate;

  for (const interval of sortedIntervals) {
    if (isBefore(currentStart, interval.startDate)) {
      dateRangesToHydrate.push({
        startDate: currentStart,
        endDate: min([endDate, subDays(interval.startDate, 1)]),
      });
    }

    currentStart = max([currentStart, addDays(interval.endDate, 1)]);
  }

  if (
    isBefore(currentStart, endDate) ||
    currentStart.getTime() === endDate.getTime()
  ) {
    dateRangesToHydrate.push({ startDate: currentStart, endDate });
  }

  return dateRangesToHydrate;
}
