import { StateContext } from '@ngxs/store';
import { append, compose, iif, patch, removeItem } from '@ngxs/store/operators';
import { ResolvedShiftWithReports } from '@wilson/interfaces';
import {
  HydrationHistory,
  ResolvedShiftsStateHistory,
  ResolvedShiftsStateModel,
} from '../resolved-shifts.state';
import { compareAsc } from 'date-fns';

export function updateHydrationHistory(
  ctx: StateContext<ResolvedShiftsStateModel>,
  startDate: Date,
  endDate: Date,
  shiftsFromServer: ResolvedShiftWithReports[],
) {
  addToHydrationHistory(ctx, startDate, endDate, shiftsFromServer);
  sortHydrationHistory(ctx);
  cleanUpHydrationHistory(ctx);
}

function addToHydrationHistory(
  ctx: StateContext<ResolvedShiftsStateModel>,
  startDate: Date,
  endDate: Date,
  shiftsFromServer: ResolvedShiftWithReports[],
) {
  if (startDate || endDate || shiftsFromServer?.length) {
    const newEntry: HydrationHistory = {
      startDate: startDate,
      endDate: endDate,
      shiftIds: shiftsFromServer
        .filter((shift) => shift.id)
        .flatMap((shift) => shift.id as string),
      date: new Date(),
    };

    ctx.setState(
      patch<ResolvedShiftsStateModel>({
        history: patch<ResolvedShiftsStateHistory>({
          hydration: append<HydrationHistory>([newEntry]),
        }),
      }),
    );
  }
}

function sortHydrationHistory(ctx: StateContext<ResolvedShiftsStateModel>) {
  ctx.setState(
    patch<ResolvedShiftsStateModel>({
      history: patch<ResolvedShiftsStateHistory>({
        hydration: (hydration) =>
          [...hydration].sort((a, b) => compareAsc(a.date, b.date)),
      }),
    }),
  );
}

function cleanUpHydrationHistory(ctx: StateContext<ResolvedShiftsStateModel>) {
  ctx.setState(
    patch<ResolvedShiftsStateModel>({
      history: patch<ResolvedShiftsStateHistory>({
        hydration: iif<HydrationHistory[]>(
          (hydration) => hydration.length > 100,
          (hydration) => hydration.slice(-100),
        ),
      }),
    }),
  );
}
