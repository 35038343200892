import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { StateOperator } from '@ngxs/store';
import {
  compose,
  iif,
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { Base } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  Activity,
  ActivityComment,
  OperationStatus,
  ResolvedActivityReport,
  ResolvedActivityWithReports,
  ResolvedShiftWithReports,
  Shift,
  ShiftState,
} from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';
import { isEqual, isWithinInterval } from 'date-fns';
import * as objectHash from 'object-hash';
import { firstValueFrom, timeout } from 'rxjs';
import {
  ResolvedShiftsStateModel,
  ResolvedShiftWithSyncStatus,
} from './state/resolved-shifts.state';
import {
  sortActivitiesForShift,
  sortCommentsForActivity,
  sortReportsForActivity,
} from './utils/custom-operators';

type CompareFn<T> = (a: T, b: T) => boolean;
export const SYNC_TIMEOUT = 10 * 1000;

@Injectable()
export class ShiftSyncService {
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  async updateShiftFromMobile(shift: Shift): Promise<void> {
    const status = await Network.getStatus();
    if (!status.connected) new Promise((resolve, reject) => reject(null));

    return firstValueFrom(
      this.http
        .post<void>(`${this.config.host}/shifts/${shift.id}/sync`, shift, {
          headers: {
            'X-RequestId': objectHash(shift),
          },
        })
        .pipe(timeout(SYNC_TIMEOUT)),
    );
  }

  updateShiftFromServer(
    shiftFromServer: ResolvedShiftWithReports,
    shiftFromState: ResolvedShiftWithSyncStatus,
  ) {
    const server = shiftFromServer;
    const state = shiftFromState;

    if (!state?.id || !server?.activities?.length) return [];

    const shiftEndedOnMobile = this.isShiftEnded(state.activities);

    const shiftFromServerIsAccepted = this.isShiftAccepted(server);

    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

    // check if shift has been changed on server
    if (this.isShiftUpdated(server, state)) {
      stateOperators.push(
        patch<ResolvedShiftsStateModel>({
          shifts: updateItem<ResolvedShiftWithSyncStatus>(
            (s) => s?.id === state?.id,
            patch<ResolvedShiftWithSyncStatus>({
              comment: server.comment,
              name: server.name,
              externalId: server.externalId,
              organizationalUnit: server.organizationalUnit,
              organizationalUnitId: server.organizationalUnitId,
              shiftCategory: server.shiftCategory,
              shiftCategoryId: server.shiftCategoryId,
              shiftReport: server.shiftReport,
              shiftScores: server.shiftScores,
              labels: server.labels,
              projectId: server.projectId,
              lastSeenAt: server.lastSeenAt,
              confirmedAt: server.confirmedAt,
              declinedAt: server.declinedAt,
              declineReason: server.declineReason,
            }),
          ),
        }),
      );

      if (state.syncedAt || shiftFromServerIsAccepted) {
        stateOperators.push(
          patch<ResolvedShiftsStateModel>({
            shifts: updateItem<ResolvedShiftWithSyncStatus>(
              (s) => s?.id === state?.id,
              patch<ResolvedShiftWithSyncStatus>({
                publicationStatus: server.publicationStatus,
                startDate: server.startDate,
                state: server.state,
              }),
            ),
          }),
        );
      }
    }

    // add activities which are only on server
    this.onlyInLeft(server.activities, state.activities).forEach((activity) => {
      stateOperators.push(
        patch<ResolvedShiftsStateModel>({
          shifts: updateItem<ResolvedShiftWithSyncStatus>(
            (s) => s?.id === state?.id,
            patch<ResolvedShiftWithSyncStatus>({
              activities: insertItem<ResolvedActivityWithReports>({
                ...activity,
                operationalStatus: shiftEndedOnMobile
                  ? OperationStatus.SkippedByUser
                  : activity.operationalStatus,
              }),
            }),
          ),
        }),
      );

      stateOperators.push(
        sortReportsForActivity(state.id as string, activity.id as string),
      );
    });

    // remove activities from state which are not on the server anymore and have not yet been interacted with
    const idsToDelete = this.onlyInLeft(state.activities, server.activities)
      .filter(
        (a) =>
          a.operationalStatus === OperationStatus.NotStarted &&
          a.createdFrom !== WilsonApp.Mobile,
      )
      .map((a) => a.id);
    idsToDelete.forEach((activityId) =>
      stateOperators.push(
        patch<ResolvedShiftsStateModel>({
          shifts: updateItem<ResolvedShiftWithSyncStatus>(
            (s) => s?.id === state?.id,
            patch<ResolvedShiftWithSyncStatus>({
              activities: updateItem<ResolvedActivityWithReports>(
                (a) => a?.id === activityId,
                patch<ResolvedActivityWithReports>({
                  deletedAt: new Date().toISOString(),
                }),
              ),
            }),
          ),
        }),
      ),
    );

    if (state.syncedAt || shiftFromServerIsAccepted) {
      // check if activities on server have been changed
      const updatedActivityOperators = this.onlyUpdated(
        server.activities,
        state.activities,
        this.isUpdatedActivity,
      ).map((serverActivity) => {
        return patch<ResolvedShiftsStateModel>({
          shifts: updateItem<ResolvedShiftWithSyncStatus>(
            (s) => s?.id === state.id,
            patch<ResolvedShiftWithSyncStatus>({
              activities: updateItem<ResolvedActivityWithReports>(
                (a) => a?.id === serverActivity?.id,
                compose(
                  patch<ResolvedActivityWithReports>({
                    activityCategory: serverActivity.activityCategory,
                    activityCategoryId: serverActivity.activityCategoryId,
                    endDatetime: serverActivity.endDatetime,
                    endLocation: serverActivity.endLocation,
                    endLocationId: serverActivity.endLocationId,
                    name: serverActivity.name,
                    service: serverActivity.service,
                    startDatetime: serverActivity.startDatetime,
                    startLocation: serverActivity.startLocation,
                    startLocationId: serverActivity.startLocationId,
                  }),
                  iif<ResolvedActivityWithReports>(
                    (stateActivity) =>
                      !!stateActivity &&
                      this.shouldUpdateOperationalStatus(
                        stateActivity,
                        serverActivity,
                      ),
                    patch<ResolvedActivityWithReports>({
                      operationalStatus: serverActivity.operationalStatus,
                    }),
                  ),
                ),
              ),
            }),
          ),
        });
      });
      stateOperators.push(...updatedActivityOperators);
    }

    // check for comments from the server and update them
    const activityCommentsInState = (state.activities ?? [])
      .flatMap((a) => a.activityComments)
      .filter((c) => c) as ActivityComment[];
    const activityCommentsOnServer = (server.activities ?? [])
      .flatMap((a) => a.activityComments)
      .filter((c) => c) as ActivityComment[];

    this.onlyInLeft(activityCommentsOnServer, activityCommentsInState).forEach(
      (comment) => {
        stateOperators.push(
          patch<ResolvedShiftsStateModel>({
            shifts: updateItem<ResolvedShiftWithSyncStatus>(
              (s) => s?.id === state.id,
              patch<ResolvedShiftWithSyncStatus>({
                activities: updateItem<ResolvedActivityWithReports>(
                  (a) => a?.id === comment?.activityId,
                  patch<ResolvedActivityWithReports>({
                    activityComments: insertItem<ActivityComment>(comment),
                  }),
                ),
              }),
            ),
          }),
        );
        stateOperators.push(
          sortCommentsForActivity(state.id as string, comment.activityId),
        );
      },
    );

    if (state.syncedAt || shiftFromServerIsAccepted) {
      // check for reports from the server and update them
      const activityReportsInState = (state.activities ?? [])
        .flatMap((a) => a.activityReports)
        .filter((c) => c) as ResolvedActivityReport[];
      const activityReportsOnServer = (server.activities ?? [])
        .flatMap((a) => a.activityReports)
        .filter((c) => c) as ResolvedActivityReport[];

      this.onlyInLeft(activityReportsOnServer, activityReportsInState).forEach(
        (report) => {
          stateOperators.push(
            patch<ResolvedShiftsStateModel>({
              shifts: updateItem<ResolvedShiftWithSyncStatus>(
                (s) => s?.id === state.id,
                patch<ResolvedShiftWithSyncStatus>({
                  activities: updateItem<ResolvedActivityWithReports>(
                    (a) => a?.id === report?.activityId,
                    patch<ResolvedActivityWithReports>({
                      activityReports:
                        insertItem<ResolvedActivityReport>(report),
                    }),
                  ),
                }),
              ),
            }),
          );
          stateOperators.push(
            sortCommentsForActivity(state.id as string, report.activityId),
          );
        },
      );
    }

    stateOperators.push(sortActivitiesForShift(state.id as string));

    return stateOperators;
  }

  cleanUpShiftStateWithinInterval(
    shiftsFromServer: ResolvedShiftWithReports[],
    shiftsFromState: ResolvedShiftWithSyncStatus[],
    currentUserId: string,
    interval: Interval,
  ) {
    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

    const shiftIdsWithConflictingUserId = shiftsFromState
      .filter((shift) => shift.userId !== currentUserId)
      .map((s) => s.id);

    shiftIdsWithConflictingUserId.forEach((shiftId) =>
      stateOperators.push(
        patch<ResolvedShiftsStateModel>({
          shifts: removeItem<ResolvedShiftWithSyncStatus>(
            (shift) => shift?.id === shiftId,
          ),
        }),
      ),
    );

    const shiftIdsInStateToRemove = this.onlyInLeft(
      shiftsFromState,
      shiftsFromServer,
    )
      .filter((shift) => this.isWithinInterval(shift, interval))
      .map((s) => s.id);
    shiftIdsInStateToRemove.forEach((shiftId) =>
      stateOperators.push(
        patch<ResolvedShiftsStateModel>({
          shifts: removeItem<ResolvedShiftWithSyncStatus>(
            (shift) => shift?.id === shiftId,
          ),
        }),
      ),
    );

    return stateOperators;
  }

  private onlyInLeft = <T extends Base>(left: T[], right: T[]): T[] => {
    return left.filter((a) => !right.some((b) => this.isSameEntity(a, b)));
  };

  private onlyUpdated = <T extends Base>(
    left: T[],
    right: T[],
    compareFn: CompareFn<T>,
  ): T[] => {
    return left.filter((a) => right.some((b) => compareFn(a, b)));
  };

  private isUpdatedActivity = (
    a: ResolvedActivityWithReports,
    b: ResolvedActivityWithReports,
  ): boolean => {
    return (
      this.isSameEntity(a, b) &&
      (!isEqual(new Date(a.startDatetime), new Date(b.startDatetime)) ||
        !isEqual(new Date(a.endDatetime), new Date(b.endDatetime)) ||
        a.startLocationId !== b.startLocationId ||
        a.endLocationId !== b.endLocationId ||
        a.operationalStatus !== b.operationalStatus ||
        a.name !== b.name ||
        a.activityCategoryId !== b.activityCategoryId)
    );
  };

  private isShiftUpdated = (
    a: ResolvedShiftWithReports,
    b: ResolvedShiftWithReports,
  ): boolean => {
    return (
      this.isSameEntity(a, b) &&
      (a.name !== b.name ||
        a.comment !== b.comment ||
        a.startDate !== b.startDate ||
        a.publicationStatus !== b.publicationStatus ||
        a.externalId !== b.externalId ||
        a.organizationalUnitId !== b.organizationalUnitId ||
        a.shiftCategoryId !== b.shiftCategoryId ||
        a.shiftScores !== b.shiftScores ||
        a.state !== b.state ||
        a.labels !== b.labels ||
        a.projectId !== b.projectId ||
        a?.lastSeenAt !== b?.lastSeenAt ||
        a?.confirmedAt !== b?.confirmedAt ||
        a?.declinedAt !== b?.declinedAt ||
        a?.declineReason !== b?.declineReason)
    );
  };

  private isSameEntity = (a: Base, b: Base): boolean => {
    return a.id === b.id;
  };

  private isShiftStarted(activities: Activity[]): boolean {
    return activities.some((a) => {
      return OperationStatus.NotStarted !== a.operationalStatus;
    });
  }

  private isShiftEnded(activities: Activity[]): boolean {
    return !activities.some((a) => {
      return (
        OperationStatus.SkippedByUser !== a.operationalStatus &&
        OperationStatus.Completed !== a.operationalStatus &&
        OperationStatus.Cancelled !== a.operationalStatus
      );
    });
  }

  private isShiftAccepted(shift: ResolvedShiftWithReports): boolean {
    return (
      shift.state === ShiftState.AcceptedTimes ||
      shift.state === ShiftState.SubmittedToPayrollProvider
    );
  }

  private isWithinInterval(
    shift: ResolvedShiftWithSyncStatus,
    interval: Interval | undefined,
  ) {
    return interval
      ? isWithinInterval(new Date(shift.startDate), interval)
      : true;
  }

  private shouldUpdateOperationalStatus(
    stateActivity: Activity,
    serverActivity: Activity,
  ) {
    const { operationalStatus: stateStatus } = stateActivity || {};
    const { operationalStatus: serverStatus } = serverActivity || {};

    if (stateStatus === serverStatus) {
      return false;
    }

    const updateableStateStatuses = [
      OperationStatus.Completed,
      OperationStatus.Ongoing,
      OperationStatus.SkippedByUser,
    ];
    const prohibittedServerStatuses = [
      OperationStatus.Ongoing,
      OperationStatus.NotStarted,
      OperationStatus.Cancelled,
    ];

    return updateableStateStatuses.includes(stateStatus)
      ? !prohibittedServerStatuses.includes(serverStatus)
      : true;
  }
}
