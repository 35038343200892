import { Injector } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TextZoom } from '@capacitor/text-zoom';
import { Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { FeatureFlagsService } from '@wilson/feature-flags';
import { LaunchDarklyUserKeys } from '@wilson/interfaces';
import { SelectLanguage } from '@wilson/languages';
import { LDOptions } from 'launchdarkly-js-client-sdk';
import { SettingsService } from './services/settings.service';
import { StorageService } from './services/storage.service';

/**
 * 1.5 prepares localization and translations
 */
async function initLanguagesAndI18N(injector: Injector, store: Store) {
  const settingsService = await injector.get(SettingsService);
  const settings = await settingsService.getSettings();

  if (settings?.selectedLanguage) {
    store.dispatch(new SelectLanguage(settings.selectedLanguage));
  }
}

async function initFeatureFlags(
  featureFlags: FeatureFlagsService,
  storage: StorageService,
) {
  const storedFlags = await storage.persistent('featureFlags');
  const hasStoredFlags = storedFlags && Object.keys(storedFlags).length;
  const options: LDOptions = hasStoredFlags
    ? { bootstrap: storedFlags }
    : undefined;

  featureFlags.initialize(
    LaunchDarklyUserKeys.WilsonMobile,
    undefined,
    options,
  );
}

/**
 * prepares libraries and data required before the app is being started
 */
async function prepareApp(
  injector: Injector,
  featureFlags: FeatureFlagsService,
  platform: Platform,
  store: Store,
  storage: StorageService,
) {
  // feature flags come first
  await initFeatureFlags(featureFlags, storage);

  // load language early on, so we can use it even during loading
  await initLanguagesAndI18N(injector, store);

  // similar or same as dom-ready
  await platform.ready();

  if (Capacitor.isNativePlatform()) {
    // set text-zoom to 1.0, ignore preferred textZoom setting (android automatically uses this)
    await TextZoom.set({ value: 1.0 });
  }
}

export function appInitializerFactory(
  injector: Injector,
  store: Store,
  featureFlags: FeatureFlagsService,
  platform: Platform,
  storage: StorageService,
) {
  return async () => {
    // minimum duration (in ms) to show splash screen for, as measured within this init step only
    // NOTE: does not take actual opening time into account
    const splashDelay = 2500;
    const startedAt = performance.now();

    // 1. prepare "generic" libraries and data
    await prepareApp(injector, featureFlags, platform, store, storage);

    // 2. switch to in-app loading screen
    // TODO: actually do that

    // 3. all done already

    // make sure we're not just showing the splash screen for a tiny second
    // NOTE: visual changes to splashscreen etc. behavior in conjunction with app component init is yet to come
    if (Capacitor.isNativePlatform()) {
      const since = performance.now() - startedAt;
      const delayDiff = splashDelay - since;
      if (delayDiff > 0) {
        await new Promise((resolve) => setTimeout(resolve, delayDiff));
      }
    }
  };
}
